/**
 * Created by hoa on 23/03/2018.
 */

define(['app', '$window', '$console', 'accessibleModalHelper',  'siteObj', 'enhancedEcom', 'accessibilityFocusHelper'], (app, $window, $console, accessibleModalHelper, siteObj, enhancedEcom, accessibilityFocusHelper) => {

  const athenaBasket = () => {
    const component = {};

    const _config = {
      attrib: {
        skuRemoveID: 'data-remove-sku',
        offerRemoveID: 'data-remove-offer',
        productID: 'data-product-id',
        productName: 'data-product-name',
        productPrice: 'data-product-price',
        quantity: 'data-product-qty',
        externalSku: 'data-product-externalSku',
        brand: 'data-product-brand',
        range: 'data-product-range',
        category: 'data-product-category',
        tierRemoveID: 'data-remove-tier',
        isAutoExpanded: 'data-auto-expand',
        freeGiftID: 'data-free-gift-id',
        modalFreeGiftID: 'data-modal-free-gift-id',
        basketFreeGiftID: 'data-basket-free-gift-id',
        hasQualified: 'data-qualifedgift',
        expiredDiscountCodeEndDate: 'data-expired-discount-code-end-date',
        discountCodeExpired: 'data-discount-code-expired',
      },
      selectors: {
        basketButton: '[data-js-element=athenaBasket_modalTriggerConditions]',
        selectedFreeProducts: '[data-current-selected]',
        qualifiedFreeGiftModal: '[data-open-qualifiedfreegift-modal]',
        continueToCheckoutButton: '[data-continuetocheckout]',
        selectFreeGiftButton: '[data-selectfreegift-button]',
        freeGiftInfoIcon: '[data-js-element=freeProductSelection_freeGiftInfoIcon]',
        toggleOpen: '[data-js-element=freeProductSelection_toggleOpen]',
        freeProductItem: '[data-product-item]',
        scrollToFreeProductsMessage: '[data-scroll-to-free-gift]',
        expiredDiscountCodeEndDate: '[data-expired-discount-code-end-date]',
        enhancedEcommerce: '.hasEnhancedEcommerce'
      },
      classNames: {
        showQualifiedFreeGiftModal: 'athenaBasket_qualifiedFreeGiftModal-show',
        closeModalClass: 'athenaBasket_qualifiedFreeGiftModal-close',
        popUpLock: 'js-popup-lock',
        continueToCheckoutButtonButtonClass: 'athenaBasket_qualifiedFreeGiftModalButton-continueToCheckout',
        toggleOpenClass: 'freeProductSelection-show',
      },
    };

    const _init = (element, bindFromInit) => {
      component.element = element;
      component.freeProductSelections = component.element.querySelectorAll('[data-freeProductSelection]');
      component.qualifiedFreeGiftModal = component.element.querySelector(component.config.selectors.qualifiedFreeGiftModal);
      component.enhancedEcommerce = component.element.querySelector(component.config.selectors.enhancedEcommerce);

      if(siteObj.basketDiscountCode) {
        if(siteObj.discountCodeApplied === 'discount.code.applied'){
          window.dataLayer.push({
            event: 'customEvent',
            eventData: {
              eventCategory: 'referral scheme',
              eventAction: 'redeemed',
              eventLabel: siteObj.basketDiscountCode,
              eventPage: ''
            }
          });
        }
      }

      if (component.freeProductSelections.length) {
        component.initialiseFreeProductDependencies(bindFromInit);
        bindFromInit && component.bindAndAttachListeners();
      }

      if(component.enhancedEcommerce || siteObj.config.useGa4EnhancedEcom === true) {
        const removeItemButtons = component.element.querySelectorAll('[data-enhanced-ecommerce-remove]');
        const addItemButtons = component.element.querySelectorAll('[data-enhanced-ecommerce-add]');
        const products = component.element.querySelectorAll('.athenaBasket_basketItemRow_productDetails');
        if(products) {
          enhancedEcom.basketView(products);
        }
        if(removeItemButtons) {
          Array.from(removeItemButtons).map(element => element.addEventListener('click', (e) => {
            component.trackEnhancedEcommerce(e, true)
          }));
        }
        if(addItemButtons) {
          Array.from(addItemButtons).map(element => element.addEventListener('click',  (e) => {
            component.trackEnhancedEcommerce(e, false)
          }));
        }
      }

      component.generateLocalisedDates();
    };

    const _generateLocalisedDates = function() {
      const endDateAlert = component.element.querySelector(
        component.config.selectors.expiredDiscountCodeEndDate,
      );
      if (endDateAlert) {
        const endDate = endDateAlert.getAttribute(
          component.config.attrib.expiredDiscountCodeEndDate,
        );
        const endDateLabel = endDateAlert.getAttribute(
          component.config.attrib.discountCodeExpired,
        );

        const localisedEndDate = new Date(endDate);

        const formattedDate = localisedEndDate.toLocaleDateString(undefined, { day: 'numeric' });
        const formattedMonth = localisedEndDate.toLocaleDateString(undefined, { month: 'long' });
        const formattedYear = localisedEndDate.toLocaleDateString(undefined, { year: 'numeric' });
        const formattedTime = localisedEndDate.toLocaleTimeString(
          undefined,
          { hour: '2-digit', minute: '2-digit' },
        ).split(' ')[0];

        endDateAlert.textContent =
          `${endDateLabel} ${formattedDate} ${formattedMonth} ${formattedYear} ${formattedTime}`;
      }
    };

    const _trackEnhancedEcommerce = (e, remove) => {
      const externalSku = e.currentTarget.getAttribute(component.config.attrib.externalSku);
      const productID = e.currentTarget.getAttribute(component.config.attrib.productID);
      const productName = e.currentTarget.getAttribute(component.config.attrib.productName);
      const productPrice = e.currentTarget.getAttribute(component.config.attrib.productPrice);
      const quantity = e.currentTarget.getAttribute(component.config.attrib.quantity);
      const brand = e.currentTarget.getAttribute(component.config.attrib.brand);
      const range = e.currentTarget.getAttribute(component.config.attrib.range);
      const fulfilment = e.currentTarget.getAttribute(component.config.attrib.fulfilment);
      const warehouseStock = e.currentTarget.getAttribute(component.config.attrib.warehouseStock);
      const category = e.currentTarget.getAttribute(component.config.attrib.category);
      const collectionType = e.currentTarget.getAttribute(component.config.attrib.collectionType);
      const deliverable = e.currentTarget.getAttribute(component.config.attrib.deliverable);
      let sku = "";
      if(externalSku) {
        sku = externalSku;
      } else {
        sku = productID;
      }

      if(remove) {
        enhancedEcom.removeFromBasket(productName, sku, productPrice, quantity, brand, fulfilment, warehouseStock, collectionType, category, deliverable, range)
      } else {
        enhancedEcom.addToBasket(siteObj.currencyType, productName, sku, productPrice, quantity, brand, fulfilment, warehouseStock, collectionType, category, deliverable, range)
      }
    }

    const _bindRemove = () => {
      const removeItemButtons = component.element.querySelectorAll('[data-js-element=athenaBasket_removeItem]');
      if (!removeItemButtons.length) {
        return;
      }
      Array.from(removeItemButtons).map(element => element.addEventListener('click', component.removeItem, false));
    };

    const _removeItem = (e) => {
      e.preventDefault();
      const skuRemoveID = e.currentTarget.getAttribute(component.config.attrib.skuRemoveID);
      const offerRemoveID = e.currentTarget.getAttribute(component.config.attrib.offerRemoveID);
      const tierRemoveID = e.currentTarget.getAttribute(component.config.attrib.tierRemoveID);
      const freeGiftId = component.element.querySelector(`[data-gift-id="${skuRemoveID}${offerRemoveID}${tierRemoveID}"]`);
      const onReload = true;
      const isComplex = freeGiftId.classList.contains('freeProductSelection_itemProductBlock_complex');

      component.freeProduct.removeUniqueItem(skuRemoveID, offerRemoveID, tierRemoveID, freeGiftId, onReload, isComplex);
    };

    const _attachListeners = () => {
      const basketButtons = component.element.querySelectorAll(component.config.selectors.basketButton);
      const continueToCheckoutButton = component.element.querySelector(component.config.selectors.continueToCheckoutButton);
      const selectFreeGiftButton = component.element.querySelector(component.config.selectors.selectFreeGiftButton);
      const infoIcon = component.element.querySelectorAll(component.config.selectors.freeGiftInfoIcon);
      const toggleEvent = component.element.querySelectorAll(component.config.selectors.toggleOpen);
      const freeProductItem = component.element.querySelectorAll(component.config.selectors.freeProductItem);
      const scrollToFreeProductsMessage = component.element.querySelectorAll(component.config.selectors.scrollToFreeProductsMessage);

      component.qualifiedFreeGiftModal.addEventListener('click', component.modalClose, false);
      Array.from(basketButtons).map(el => el.addEventListener('click', component.modalTriggerConditions));
      continueToCheckoutButton.addEventListener('click', component.continueToCheckout);
      selectFreeGiftButton.addEventListener('click', component.modalScrollToOffer);
      Array.from(toggleEvent).map(el => el.addEventListener('click', component.clickCounter));
      Array.from(freeProductItem).map(el => el.addEventListener('click', component.clickCounter));
      Array.from(scrollToFreeProductsMessage).map(el => el.addEventListener('click', component.discountMessageScrollToOffer));

      if (!infoIcon.length) {
        return;
      }
      Array.from(infoIcon).map(el => el.addEventListener('click', component.clickCounter));
    };

    const _clickCounter = () => {
      component.clickCount++;
    };

    const _modalTriggerConditions = (e) => {
      if (component.freeProductSelections[0]) {
        let hasQualified = component.freeProductSelections[0].getAttribute(component.config.attrib.hasQualified);
        if (hasQualified === 'false') return;
      }

      const selections = component.element.querySelectorAll(component.config.selectors.selectedFreeProducts);
      const arr = Array.from(selections).map(el => parseInt(el.innerHTML));
      const currentSelectionCount = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      const isAutoExpanded = Array.from(component.freeProductSelections).map(el => el.getAttribute(component.config.attrib.isAutoExpanded));
      let conditions = false;

      if (isAutoExpanded[0] === 'true' || component.clickCount === 0) {
        conditions = true;
      }

      if (component.clickCount > 0) {
        conditions = false;
      }

      if (currentSelectionCount === 0 && conditions && !component.hasAcknowledgedFreeGift) {
        e.preventDefault();
        component.showModal();
      } else {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to go straight to checkout');
      }
    };

    const _showModal = () => {
      component.prevScrollTop = $window.pageYOffset;

      component.hasAcknowledgedFreeGift = true;
      const openModal = component.qualifiedFreeGiftModal;

      openModal.classList.add(component.config.classNames.showQualifiedFreeGiftModal);
      document.documentElement.classList.add(component.config.classNames.popUpLock);
      document.body.style.top = `${-component.prevScrollTop}px`;

      component.accessibleModalHelper = new accessibleModalHelper(
        document.querySelector('.athenaBasket_qualifiedFreeGiftModal'),
        () => component.hideModal('ESCAPE'),
        document.querySelector('.athenaBasket_qualifiedFreeGiftModalTitle'),
      );

      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Modal Shown');
    };

    const _hideModal = (element) => {
      const underlay = component.qualifiedFreeGiftModal;
      const closeModalButton = typeof element === 'object' ? element.classList.contains(component.config.classNames.closeModalClass) : false;

      underlay.classList.remove(component.config.classNames.showQualifiedFreeGiftModal);
      document.documentElement.classList.remove(component.config.classNames.popUpLock);
      document.body.style.top = '';
      app.element.scrollTo(component.prevScrollTop);

      component.accessibleModalHelper && component.accessibleModalHelper.close();

      if (element === underlay) {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked on the Modal Underlay');
      }

      if (closeModalButton) {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked on X to Close Modal');
      }

      if (element === 'ESCAPE') {
        app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Pressed ESCAPE key to Close Modal');
      }
    };

    const _modalClose = (e) => {
      e.preventDefault();

      if (e.target.classList.contains(component.config.classNames.showQualifiedFreeGiftModal)
        || e.target.classList.contains(component.config.classNames.closeModalClass)) {
        component.hideModal(e.target);
      }
    };

    const _continueToCheckout = (e) => {
      $window.location = "/checkoutStart.account";
      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to Checkout in modal');
    };

    const _modalScrollToOffer = (e) => {
      component.hideModal(e.target);

      component.scrollToFreeGift();

      app.publish('tracking/record', 'athenaBasket_qualifiedFreeGiftModal', 'Clicked to Select Free Gift in modal');
    };

    const _discountMessageScrollToOffer = (e) => {
      const discountMessageOfferId = e.target.getAttribute(component.config.attrib.basketFreeGiftID);

      component.scrollToFreeGift(discountMessageOfferId);

      app.publish('tracking/record', 'athenaBasket_discountAlert', 'Clicked to Select Free Gift in alert');
    };

    const _scrollToFreeGift = (id) => {
      component.hasAcknowledgedFreeGift = true;
      const freeGiftComponent = component.element.querySelector(`[data-free-gift-id="${id}"]`) || component.element.querySelector('[data-qualifedGift=true]');
      const freeGiftComponentTitle = freeGiftComponent.querySelector(component.config.selectors.toggleOpen);

      accessibilityFocusHelper.focus(freeGiftComponentTitle);

      if (!freeGiftComponent) return $console.warn(`athenaBasket: cannot find [data-free-gift-id="${id}"] or [data-freeProductSelection].`);

      freeGiftComponent.classList.add(component.config.classNames.toggleOpenClass);
      app.element.scrollTo(freeGiftComponent, 200);
    };

    const _initialiseFreeProductDependencies = (bindFromInit) => {
      require(['freeProductTiers'], function (freeProductTiers) {
        component.freeProduct = freeProductTiers();

        require(['freeProductSelection'], function (freeProductSelection) {
          Array.from(component.freeProductSelections).map((el) => {
            freeProductSelection().init(el);
          });

          !bindFromInit && component.bindAndAttachListeners();

        });
      });
    };

    const _bindAndAttachListeners = () => {
      if (component.qualifiedFreeGiftModal) {
        component.hasAcknowledgedFreeGift = false;
        component.clickCount = 0;
        component.attachListeners();
      }

      component.bindRemove();
    };

    component.config = _config;
    component.init = _init;
    component.generateLocalisedDates = _generateLocalisedDates;
    component.bindRemove = _bindRemove;
    component.removeItem = _removeItem;
    component.attachListeners = _attachListeners;
    component.clickCounter = _clickCounter;
    component.modalTriggerConditions = _modalTriggerConditions;
    component.showModal = _showModal;
    component.hideModal = _hideModal;
    component.modalClose = _modalClose;
    component.continueToCheckout = _continueToCheckout;
    component.modalScrollToOffer = _modalScrollToOffer;
    component.discountMessageScrollToOffer = _discountMessageScrollToOffer;
    component.scrollToFreeGift = _scrollToFreeGift;
    component.initialiseFreeProductDependencies = _initialiseFreeProductDependencies;
    component.bindAndAttachListeners = _bindAndAttachListeners;
    component.trackEnhancedEcommerce = _trackEnhancedEcommerce;

    return component;
  };

  return athenaBasket;
});
